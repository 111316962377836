<template>
  <div class="cart_item_2">
    <div class="iimg" v-if="obj" style="width: 20%;">
      <img class="small-img" :src="obj.product[0].productImages[0]" alt="No image found" />
    </div>
    <div style="display: flex; flex-direction: column; justify-content: space-between; width: 55%;">
      <div class="title_item">{{ obj && obj.product.length ? shortName(obj.product[0].name) : "" }} -
        {{ obj && obj.product.length ? obj.variant[0].name : "" }}</div>
      <div>
        <div class="price green">
          {{
            obj && obj.variant.length
            ? `₹${((obj.variant[0].priceOffer || obj.variant[0].priceOriginal) *
              quantity)}`
            : ""
          }}
        </div>
        <div class="per_piece_price">Price per piece <span class="green">{{
          obj && obj.variant.length
          ? `₹${((obj.variant[0].priceOffer || obj.variant[0].priceOriginal))}`
          : ""
        }}</span></div>
      </div>
    </div>
    <div style="display: flex; flex-direction: column; justify-content: space-between; width: 20%;">
      <div class="del" @click="deleteItem">
        <img src="../../../assets/yicimages/store/trash.png" />
      </div>
      <div class="calc">
        <span class="spc" @click="quantityChangeHandler('-')">-</span>
        <span class="spx">{{ quantity }}</span>
        <span class="spc" @click="quantityChangeHandler('+')">+</span>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>
<script>
import { BModal, VBModal, BAlert, BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
} from "bootstrap-vue";

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BRow,
    BImg,
    BCol,
    BLink,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  props: ["obj"],
  directives: {
    Ripple,
  },
  data() {
    return {
      quantity: this.obj ? this.obj.quantity : 1,
    };
  },
  methods: {
    onClick() { },
    ...mapActions({
      updateQuantity: "storeShoppingCartItems/updateQuantity",
      deleteFromCart: "storeShoppingCartItems/deleteFromCart",
    }),
    async quantityChangeHandler(operation) {
      //what is user makes this quantity 0
      // console.log(this.obj,'bb');
      if (operation === "-" && this.quantity > 1) this.quantity--;
      else if (operation === "+" && (this.quantity + 1 <= this.obj.variant[0].inventoryQuantity)) this.quantity++;
      const quan = this.quantity;
      const id = this.obj.id;
      await this.updateQuantity({ id, quan });
      this.$emit("reload");
    },
    // localStorage.setItem("userIddd", "hii");
    // console.log(localStorage, localStorage.getItem("userIddd"));
    async deleteItem() {
      const id = this.obj.id;
      await this.deleteFromCart(id);
      this.$emit("reload");
    },
    shortName(name) {
      let str = name;
      if (str.length > 15) return str.slice(0, 15) + '...'
      return name
    },
  },
  mounted() {
    // this.fetchCartProduct(parseInt(this.obj.productId));
  },
};
</script>
<style scoped>
.cart_item_2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0.625rem;
  background: #FFF;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.05);
  padding: 1rem;
  width: 100%;
  min-height: 10rem;
  max-height: 12rem;
}

.title_item {
  font-weight: 600;
  font-size: 1.5rem;
  color: #000;
  word-wrap: break-word;
}

.quan {
  font-weight: 500;
  font-size: 20px;
  color: #8a8888;
  /* margin-bottom: 2.5rem; */
}

.price {
  font-weight: 500;
  font-size: 1.5rem;
  word-wrap: none;
}

.blue {
  color: #4385f5;
}

.del {
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: black;
  margin-left: 3rem;
  margin-top: 1rem;
}

.calc {
  width: 8rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border: 1px solid #D9D9D9;
  border-radius: 2rem;
  height: 2.5rem;
}

.per_piece_price {
  font-size: 1rem;
  color: #000;
  font-weight: 500;
}

.spc {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 1.5rem;
  cursor: pointer;
}

.spx {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: #000;
}

.thr {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.tw {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  padding: 1rem;
}

.green {
  color: #0EB100;
}

.iimg {
  padding: 1.5rem;
  overflow: hidden;
  border-radius: 0.5rem;
  margin-right: 1rem;
}

.calc>span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.small-img {
  object-fit: contain;
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
}

.xyz {
  display: flex;
  width: 100%;
}


/*========================= Media Queries (Small devices) =========================*/
@media screen and (max-width: 600px) {
  .cart_item {
    /* display: flex;
    padding: 2rem 1rem;
    border-bottom: 2px solid #c9c9c9; */
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .quan {

    display: none;
  }

  .title {
    font-weight: 500;
    font-size: 23px;
    color: #414141;
    max-width: 180px;
    word-wrap: break-word
      /* display: flex; */
      /* flex-wrap: wrap; */
  }

  .iimg {
    max-width: 160px;
    max-height: 200px;
    background: #ebebeb;
    margin-right: 1rem;
    padding: 1rem;
    overflow: hidden;
  }

  .xyz {
    flex-wrap: wrap;
  }

  .thr {
    margin-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    /* flex-wrap: wrap; */
  }

}

@media screen and (max-width: 400px) {
  .title {
    width: 150px;
  }
}
</style>
      