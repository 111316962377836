<template>
  <div class="rrroot">
    <EmptyCart v-if="!price" />
    <div class="xyz" v-else>
      <div v-if="isLoading" class="loader"></div>
      <div class="root_p" :class="[isLoading ? 'backdrop' : '']">
        <div class="left_p">
          <!-- navbar -->
          <div class="navv">
            <div class="poin" @click="onClick('cart')">Cart</div>
            <div class="blue">>></div>
            <div class="blue poin">Shipping &amp; Payment</div>
          </div>
          <div v-if="!isLogin && price" class="loginlin">
            You are not logged in.
            <span @click="onClick('login')" class="poin font-weight-bold blue">Login</span>
            <span class="blue"> / </span>
            <span @click="onClick('register-demo')" class="poin font-weight-bold blue">Signup</span>
            to continue
          </div>
          <!-- heading -->
  
          <div class="add_box">
            <div class="title___">{{ suitAddress ? 'Shipping Address' : '' }}</div>
            <div class="addressX">{{ suitAddress ? suitAddress.replace(/#/g, ' , ') : '' }}</div>
          </div>
          <!-- Footer -->
          <div class="footer_1" v-if="isLogin">
            <input type="checkbox" id="ckb" class="poin" v-model="termsAccepted" />
            <label for="ckb" class="statmnt poin">Items bought from YIC can not be returned.</label>
          </div>
          <div class="footer_2">
            <div class="blue poin" @click="onClick('cart')">&lt; Return to cart</div>
            <b-button class="bttn2" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="makeOrder"
              :disabled="!(isLogin && termsAccepted)">
              CONTINUE TO PAYMENT
            </b-button>
          </div>
        </div>
        <!-- right section -->
        <div class="right_p">
          <!-- cart item -->
          <div class="item_" v-for="obj in items" :key="obj.id">
            <CartItemVue :obj="obj"  @reload="reloadCart" />
          </div>

          <!-- total cost -->
          <div class="total_">
            <div class="lef">Total</div>
            <div class="rig">₹ {{ this.storeState.finalPrice }}
              <!-- {{ userDetails }} -->
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { BModal, VBModal, BAlert, BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
} from "bootstrap-vue";
import EmptyCart from "../ShoppingCart/EmptyCart.vue";
import yicLogo from '../../../assets/yicimages/logo/YourIndianCart_logo-1-color.jpg';
import CartItemVue from './CartItem.vue'
export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BRow,
    BImg,
    BCol,
    BLink,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    EmptyCart,
    CartItemVue
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      isOrderCreated: false,
      termsAccepted: false,
      quantity: 1,
    };
  },
  computed: mapState({
    isLogin(state) {
      return state.login.isAuthenticated;
    },
    storeState(state) {
      return state.storeShoppingCartItems;
    },
    userDetails: (state) => {
      return state.basicprofilemodule;
    },
    items(state) {
      return state.storeShoppingCartItems.shoppingCartItems;
    },
    price(state) {
      return state.storeShoppingCartItems.finalPrice;
    },
    currUser(state) {
      return state.login.currUser;
    },
    suitAddress(state) {
      return state.storeShoppingCartItems.suitAddress;
    },
    paymentGatewayOption: state => {
      return state.storeShoppingCartItems.paymentGatewayOption
    },
    ccAvenueResponse(state) {
      return state.storeShoppingCartItems.ccAvenueResponse;
    },
    orderId(state) {
      return state.storeShoppingCartItems.orderId;
    },
    isPaymentDone(state) {
      return state.storeShoppingCartItems.paymentDone;
    },
  }),
  watch: {
    // isLogin(nv, ov) {
    //   if (nv) this.getUserDetails();
    // },
  },
  async mounted() {
    await this.reloadCart();
    const userId = this.currUser ? this.currUser.id : null;
    await this.fetchShoppingCartItems({ userId });
    console.log('userid', userId);
    await this.fetchSuitAddress({ userId });
    console.log('suit', this.suitAddress);
    await this.calculateCost();
    if (this.currUser.id) {
      await this.getUserDetails();
    }
    console.log(userId, 'hiiiiii');

    if (userId) {
      this.setIsLoading(true);
      // console.log("in mounted storePayment", this.orderId);
      const tempOrderId = this.$route.query.orderId;
      console.log(tempOrderId);
      if (tempOrderId) {
        console.log('set start');
        await this.checkOrderStatus({ userId, orderId: tempOrderId })
        console.log('set end');
        // checking if payment is done..
        if (this.orderId) {
          console.log('status end');
          if (this.isPaymentDone) {
            console.log('pay enter');
            this.ccAvenuePaymentDoneHandler();
            this.setIsLoading(false);
            this.paymentSucessHandler();
          } else {
            this.setIsLoading(false);
            this.showFailureAlert()
          }
        }
      }
      this.setIsLoading(false);
    }

  },
  methods: {
    showSuccessAlert() {
      // Use sweetalert2
      const this__ = this;
      this.$swal({
        icon: 'success',
        title: 'Payment Sucessful',
        text: 'Thanks for purchasing from YIC! You can find your products in Dashboard. You will be redirected to Store',
        confirmButtonText: 'Continue'
      }).then(function () {
        this__.$router.push({ name: 'store' });
      });
    },
    showFailureAlert() {
      // Use sweetalert2
      const this__ = this;
      this.$swal({
        icon: 'error',
        title: 'Payment Failed',
        text: 'Try checkout again, you will be redirected to the cart',
        confirmButtonText: 'Continue'
      })
        .then(function () {
          this__.$router.push({ name: 'cart' });
        });
    },
    setIsLoading(val) {
      this.isLoading = val;
    },
    onClick(place) {
      this.$router.push({ name: place });
    },
    ...mapActions({
      createOrder: "storeShoppingCartItems/createOrder",
      fetchShoppingCartItems: "storeShoppingCartItems/setShoppingCartItems",
      calculateCost: "storeShoppingCartItems/calculateCost",
      getUserDetails: "basicprofilemodule/getUserDetails",
      createRazorpayPaymentsSuccess:
        "storeShoppingCartItems/createRazorpayPaymentsSuccess",
      addUserIdToCartItems: "storePayments/addUserIdToCartItems",
      fetchSuitAddress: "storeShoppingCartItems/fetchSuitAddress",
      checkOrderStatus: "storeShoppingCartItems/checkOrderStatus",
      ccAvenuePaymentDoneHandler: "storeShoppingCartItems/ccAvenuePaymentDoneHandler",
    }),
    async makeOrder() {
      await this.createOrder();
      if (this.paymentGatewayOption == 'CCAvenue') {
        this.ccAvenuePaymentHandler();
      }
      else {
        this.setIsLoading(true);
        this.isOrderCreated = true;
        
        this.razorpayPaymentHandler();
      }
    },
    // CCAvenue Paymnt handler
    async ccAvenuePaymentHandler() {
      // await this.createOrder();
      this.$router.push({
        name: "ccAvenueCheckout",
        params: {
          merchantId: this.ccAvenueResponse.merchantId,
          accessCode: this.ccAvenueResponse.accessCode,
          encReq: this.ccAvenueResponse.encryptedOrderData
        },
      });
    },
    // Razorpay payment Handler
    async razorpayPaymentHandler() {
      const this__ = this;
      // const price =  * 100;
      // console.log('************', this.storeState.finalPrice);
      var options = {
        key: this.storeState.razorpayKeyId, // Enter the Key ID generated from the Dashboard
        amount: this.storeState.finalPrice, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Your Indian Cart",
        description: "Test Transaction",
        image: yicLogo,
        // "https://www.rugastech.com/wp-content/uploads/2018/07/rugas-3.png",
        order_id: this.storeState.razorpayOrderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: function (response) {
          this__.createRazorpayPaymentsSuccess({
            razorpayOrderId: response.razorpay_order_id,
            paymentId: response.razorpay_payment_id,
            signature: response.razorpay_signature,
          });
          this__.paymentSucessHandler();
        },
        prefill: {
          name: this.userDetails.firstName,
          email: this.userDetails.email,
          contact: this.userDetails.contactNumber,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
        "modal": {
          "ondismiss": function () {
            // console.log('Checkout form closed');
          }
        },
        retry: false
      };
      var rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", async function (response) {
        this__.showFailureAlert();
      });
      this.setIsLoading(false);
      rzp1.open();
    },
    async paymentSucessHandler() {
      this.setIsLoading(true);
      const userId = this.currUser ? this.currUser.id : null;
      await this.fetchShoppingCartItems({ userId });
      this.setIsLoading(false);
      this.showSuccessAlert();
    },
    async reloadCart() {
      const userId = this.currUser ? this.currUser.id : null;
      await this.fetchShoppingCartItems({ userId });
      this.calculateCost();
    },
  },
};
</script>
<style scoped>
.root_p {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem 8rem;
}

.navv {
  font-weight: 500;
  font-size: 20px;
  color: #414141;
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.bttn2 {
  min-width: 250px;
  max-height: 40px;
  border: 1px solid #4385f5;
  background: #4385f5;
  color: #fff;
  margin-right: 2rem;
}

.blue {
  color: #4385f5;
}

.loginlin {
  font-weight: 400;
  font-size: 18px;
  color: #000;
  margin: 1rem 0;
}

.title___ {
  font-weight: 500;
  font-size: 20px;
  color: #000000;
}

.addressX {
  font-size: 18px;
}

.right_p {
  background: rgba(248, 248, 248, 0.5);
  padding: 0 2rem;
}

.title {
  font-weight: 500;
  font-size: 20px;
  color: #414141;
  word-wrap: break-word
}

.quan {
  font-weight: 500;
  font-size: 16px;
}

.item_ {
  display: flex;
  gap: 2rem;
  padding: 1rem 0;
}

.item_pic {
  width: 96px;
  height: 120px;
  background: #ebebeb;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item_pic>img {
  width: 100%;
  object-fit: contain;
  overflow: hidden;
}

.item_l,
.item_r {
  margin-top: 1.5rem;
}

.item_r {
  font-weight: 500;
  font-size: 20px;
  margin: auto;
  margin-right: 0;
}

.total_ {
  font-weight: 500;
  font-size: 21px;
  color: #000;
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
}

.poin {
  cursor: pointer;
}


.small-img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}


.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -100px;
  width: 48px;
  height: 48px;
  border: 5px solid #4385f5;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.add_box {
  border-radius: 0.5rem;
  border: 2px solid #00BCD4;
  padding: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
}


@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.backdrop {
  /* position: fixed; */
  /* background: rgba(0, 0, 0, 0.5); */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

.footer_2 {
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2rem;
  gap: 1rem;
}

.footer_1 {
  font-size: 18px;
  /* font-weight: 600; */
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  /* align-items: center;  */
  margin-bottom: 2rem;
  gap: 0.5rem;
}

.statmnt {
  font-size: 15px;
  margin-bottom: 0;
}


/*========================= Media Queries (Small devices) =========================*/
@media screen and (max-width: 600px) {

  .root_p {
    display: grid;
    grid-template-columns: 1fr;
    padding: 1rem;
  }

  .addressX {
    margin-top: 1rem;
    margin-bottom: 5rem;
  }

  .right_p {
    background: rgba(248, 248, 248, 0.5);
    padding: 1rem;
  }

  .title {
    /* display: flex; */
    max-width: 160px;
    word-wrap: break-word
  }

  .item_ {
    display: grid;
    gap: 0;
    grid-template-columns: 1fr 1fr;
    /* border-bottom: 2px solid #c9c9c9; */
    /* padding: 2rem 0; */
  }

  .bttn2 {
    margin-right: 0;
  }
}
</style>